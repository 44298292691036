import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Button from "../components/button"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        <div style={{ margin: "40px 0 40px" }}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <React.Fragment>
                <div key={node.fields.slug} style={{paddingLeft: '15px'}}>
                  <h3
                    className="title is-5"
                    style={{
                      marginBottom: rhythm(1 / 30),
                    }}
                  >
                    <Link
                      style={{ boxShadow: `none` }}
                      to={`blog${node.fields.slug}`}
                    >
                      {title}
                    </Link>
                  </h3>
                  <p style={{marginBottom: rhythm(1 / 3)}}>
                    <small>
                      <span role="img" aria-label="time and date clock emoji">🕔 </span>
                      {node.frontmatter.date}
                    </small>
                  </p>
                  <p
                    style={{
                      marginBottom: rhythm(1 / 2),
                      marginTop: rhythm(1 / 4)
                    }}
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  /> 
              </div>
              <hr style={{borderTop: '1px solid #bbb', margin: '0 0 15px'}} />
              </React.Fragment>
            )
          })}
        </div>
        <Link to="/">
          <Button marginTop="85px">Go Home</Button>
        </Link>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC } filter: {fileAbsolutePath: {regex: "/content/blog/"}}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
          body
        }
      }
    }
  }
`
